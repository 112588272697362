<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <orisis-logo-full />
        </b-link>

        <b-card-title class="mb-1">
          Vous êtes actuellement sur l'espace de travail :
          "<b class="text-primary">{{ selectedWorkspace.socialReason }}</b>"
        </b-card-title>
        <b-card-text class="mb-2">
          Changez votre espace de travail actuel !
        </b-card-text>
        <v-select :options="userInfo.workspaces" label="socialReason" v-model="newWorkspaceSelectedId" :reduce="(type) => type.id"/>
        <b-button
          :style="newWorkspaceSelectedId == selectedWorkspace.id || !newWorkspaceSelectedId ? 'cursor: not-allowed' : ''"
          :disabled="newWorkspaceSelectedId == selectedWorkspace.id || !newWorkspaceSelectedId"
          class="mt-2"
          variant="primary"
          type="submit"
          block
          @click="changeWorkspace(newWorkspaceSelectedId)"
        >
          Changer
        </b-button>
      </b-card>
    </div>
  </div>
</template>
  
<script>
import OrisisLogoFull from "@core/layouts/components/LogoFull.vue";
import {
  BCard, 
  BLink, 
  BCardTitle, 
  BCardText, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BCard, 
    BLink, 
    BCardTitle, 
    BCardText, 
    OrisisLogoFull,
    vSelect,
  },
  data(){
    return{
      newWorkspaceSelectedId: null,
    }
  },
  created() {
    this.fetchWorkspacesList()
    this.newWorkspaceSelectedId = this.$store.getters.workspaceSelected.id
  },
  methods:{
    ...mapActions(["fetchWorkspacesList"]),
    changeWorkspace(workspaceId){
      this.$store.dispatch('setWorkspaceSelected', {workspaceIdSelected: workspaceId}).then(() => {
        this.$tabs.close();
        this.$tabs.refreshAll();
      })
    },
  },
  computed:{
    ...mapGetters(["workspacesList", "userInfo"]),
    selectedWorkspace() {
      return this.$store.getters.workspaceSelected
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>